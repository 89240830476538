

footer.copyright {
  .copyright-txt {
    font-size: 0.6rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  .financed {
    display: flex;
    align-items: baseline;
    width: 100%;
    .eu-logo {
      flex-grow: 1;
      text-align: left;
    }
    .other-logo {
      flex-grow: 1;
      text-align: right;
    }
    img {
      width: auto;
      &.eu {
        height: 30px;
      }
      &.ministerio {
        height: 24px;
      }
      &.prtr {
        height: 48px;
      }
      @media screen and (min-width: 600px) {
        &.eu {
          height: 50px;
        }
        &.ministerio {
          height: 40px;
        }
        &.prtr {
          height: 80px;
        }
      }
    }
  }
}