@import "../../styles/bootstrap/bootstrap_redefinition";
@import "~proxia-react/styles/bs4-units";
@import "~bootstrap-scss/functions";
@import "~bootstrap-scss/variables";
@import "../../styles/variables";

header.mainHeader {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }

  .logos {
    display: flex;
    flex-direction: row;
    img {
      width: auto;
      height: 100%;
    }
    height: 48px;
    justify-content: center;
    @media screen and (min-width: 600px){
      justify-content: left;
      .txt {
        display: none;
      }
    }
    @media screen and (min-width: 1080px) {
      .txt {
        display: block;
      }
    }


    > * {
      flex: 0 0 0;
    }
  }


  h1 {
    flex-grow: 1;
    text-align: center;
    font-weight: 600;
    font-size: $font-size-lg;
    @media screen  and (min-width: 600px){
      font-size: $h3-font-size;
      text-align: right;
    }
    font-size: $h3-font-size;
    @media screen and (min-width: 1080px) {
      font-size: $h2-font-size;
    }

  }
}