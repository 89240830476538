section.preferences {
  h2 {
    font-size: $font-size-base*1.1;
    border-bottom: 1px solid $gray-200;
    padding-bottom: $min-inline-element-spacing;
    margin-bottom: $min-inline-element-spacing;
    button {
      font-weight: inherit;
      display: flex;
      width: 100%;
      .titleName {
        flex-grow: 1;
        text-align: left;
      }
      .icon {
        flex-grow: 0;
        text-align: right;
      }
      @include a-events-hover() {
        color: $body-color;
        text-decoration: none;
        .icon {
          color: $primary;
        }
      }
    }
  }
}