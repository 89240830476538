div.login-view {
  background-color: $gray-100;

  @media screen and (min-width: 768px) {
    padding: $grid-gutter-width;
  }
  @media screen and (min-width: 850px) {
    padding: 2*$grid-gutter-width;
  }
  display: flex;
  flex-direction: row;

  > * {
    flex: 1 0 0;
    height: 100vh;
    @media screen and (min-width: 768px) {
      height: calc(100vh - 2*$grid-gutter-width);
    }
    @media screen and (min-width: 850px) {
      height: calc(100vh - 4*$grid-gutter-width);
    }

    @media screen and (min-width: 768px) {
      min-height: 650px;
    }
  }
  > .detail {
    display: none;
    @media screen and (min-width: 1200px){
      display: block;
    }
    border-radius: 0px $grid-gutter-width $grid-gutter-width 0px;
    background-color: $gray-300;
    background-image: url("../../assets/background.png");
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  > .form-container {
    flex-grow: 1;
    @media screen and (min-width: 1200px) {
      flex: 0 0 60%;
    }
    header {
      border-bottom: 2px solid $primary;
    }
    @media screen and (min-width: 768px) {
      header {
        border-bottom: 0px;
      }
      border-radius: $grid-gutter-width;
    }
    @media screen and (min-width: 1200px) {
      border-radius: $grid-gutter-width 0px 0px $grid-gutter-width;
    }
    background-color: white;
    padding: $grid-gutter-width $halfGutter;
    @media screen and (min-width: 768px) {
      padding: $grid-gutter-width;
    }
    display: flex;
    flex-direction: column;

    .form {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      form {
        width: 85%;
      }

      div.actions {
        margin-top: $halfGutter;
        text-align: right;
        button {
          width: 100%;
        }
      }
    }
  }
}

