// Variables definidas por el soporte de Proxia.
//
$halfGutter: $grid-gutter-width / 2;
$min-inline-element-spacing: rem(6px)*($font-size-base/1rem);

$font-size-xlg : 1.05*$font-size-lg;
$font-size-xsm : 0.95*$font-size-sm;

/// Variables para servicios REACT con el soporte de formularios
/// @type Number
$form-control-font-size: $font-size-base ;
$form-control-radio-size: 15px; // Debe ser en pixel.


/// Borde de los controles de formulario REACT
/// @type Number
$form-control-border-radius: 0px ;

/// Tamaño de fuente de la leyenda en formularios REACT
/// @type Number
$form-control-legend-font-size: $font-size-lg ;

/// Tamaño de fuente de la etiqueta en formularios REACT
/// @type Number
$form-control-label-font-size: $font-size-sm;

/// Color del borde
/// @type Color
$form-control-border-color: #cccc ;

/// Fondo del control
/// @type Color
$form-control-bgcolor: white ;

/// Padding para los controles de formulario estándar
/// @type Number
$form-control-padding: $min-inline-element-spacing $halfGutter !default;

$form-control-errorarea-anyof-float: true;

$dropzone-background: $gray-200;
$dropzone-filearea-color: $gray-600;
$dropzone-imagearea-color: $gray-600;


$form-control-padding: 0.375rem 0.375rem;

$form-control-line-height: $form-control-font-size * $line-height-base;

$content-control-item-icon: '';
$master-view-title-font-size: $font-size-base;