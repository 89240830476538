$min-sidebar-width: rem(60px);
$normal-sidebar-width: rem(250px)*stripUnit($font-size-base);



@mixin minimized() {
  .content {
    margin-left: $min-sidebar-width + 3*$halfGutter;
  }
  .sidebar {
    width: $min-sidebar-width;
    padding: $min-inline-element-spacing;
    h1 {
      display: none;
    }

    .txt,
    .user-data .welcome {
      display: none;
    }

    .logos .button {
      left: rem(35px);
      top: rem(45px)*stripUnit($font-size-base);
      @include fontBlock('Font Awesome 6 Free', '\f105') {
        font-family: var(--fa-style-family, "Font Awesome 6 Free");
        font-weight: var(--fa-style, 900);
        color: white;
        padding-top: 0.5rem;
        display: block;
      }
    }

    .logos img {
      height: 44px;
    }

    .user-data .avatar {
      width: 50px;
      height: 50px;
    }

    .exit {
      margin-left: 0px;
      align-self: center;
    }

    ul.menu {
      margin-left: 0px;
      align-self: center;

      .item-txt {
        display: none;
      }
    }
  }
}

@mixin mobile() {
  .content {
    margin-left: $halfGutter;
    position: relative;
    top: $min-sidebar-width;
    padding-top: 10px;
    div.header {
      position: fixed;
      margin-top: -65px;
      z-index: 5;
      margin-left: 55px;
      width: calc(100% - 80px);
      h1.mobile {
        display: block;
        font-size: 1.3rem;
        line-height: 1;
      }
      .content-header h2 {
        width: 100%;
      }
    }
  }

  .sidebar {
    h1 {
      display: none;
    }
    border-right: none;
  }

  &:not(.minimized) .sidebar {
    width: 100%;
    height:$min-sidebar-width;
    padding: $min-inline-element-spacing;
    .button {
      display: none;
    }
    border-bottom: 1px solid $gray-300;
    z-index: 3;

    .user-data, .menu, .exit, .txt {
      display: none;
    }
  }
  &.minimized {
    .content {
      display: none;
    }
    .sidebar {
      width: 100%;
      .button {
        display: none;
      }
      h1 {
        display: block;
      }
      .fullname {
        display: block;
      }
    }
  }
}

div.layout {
  @media screen and (min-width: 768px) {
    &.minimized {
      @include minimized();
    }
  }

  .content  {
    margin: 0 $halfGutter 0 (3*$halfGutter + $normal-sidebar-width);
    .section {
      min-height: calc(100vh - 5rem);
    }
    .copyright {

      .copyright-txt {
        display: none;
      }
    }
    h1 {
      color: $primary;
      margin-bottom: 0rem;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    .content-header h2 {
      color: $body-color;
      margin-bottom: $grid-gutter-width;
      @media screen and (min-width: 768px) {
        font-size: 2rem;
        color: $primary;
      }
    }

  }

  .sidebar {
    position: fixed;
    background-color: $gray-100;
    //height: 100%;
    width: $normal-sidebar-width;
    border-right: 1px solid $gray-400;
    padding: $halfGutter;
    display: flex;
    flex-direction: column;
    height: 100vh;
    h1 {
      font-size: 1rem;
      text-align: center;
      color: $primary;
      margin-top: $halfGutter;
      display: block;
    }
  }

  .logos {
    display: flex;
    position: relative;
    flex-direction: row;
    margin-left: 4px; // Tenemos la anchura del borde
    img {
      height: 50px;
      width: auto;
    }
    .txt {
        margin-left: $halfGutter;
    }
    .button {
      width: 30px;
      height: 30px;
      background: $gray-400;
      border-radius: 50%;
      position: absolute;
      left: rem(215px)*stripUnit($font-size-base);
      top: rem(110px)*stripUnit($font-size-base);
      z-index: 3;

      @include fontBlock('Font Awesome 6 Free','\f104') {
        font-family: var(--fa-style-family, "Font Awesome 6 Free");
        font-weight: var(--fa-style, 900);
        color: white;
        padding-top: 10px;
        display: block;
      }
    }

  }

  .user-data {
    margin-top: $halfGutter;
    padding-top: $halfGutter;
    margin-bottom: $halfGutter;
    padding-bottom: $halfGutter;
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;
    display: flex;
    flex-direction: row;
    color: $body-color;
    @include a-events-hover() {
      color: $body-color;
      text-decoration: none;
      .avatar {
        border-color: $primary;
      }
    }
    .avatar {
      width: $min-sidebar-width;
      height: $min-sidebar-width;
      border-radius: 50%;
      border: 5px solid white;
      background-color: $gray-300;
      font-weight: 600;
      font-size: $font-size-xlg;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }
    .welcome {
      margin-left: $halfGutter;
      .salutation {
        font-size: $font-size-sm;
        font-weight: 600;
        color: $gray-700;
      }
      .fullname {
        font-size: $font-size-lg;
        font-weight: 600;
        width: rem(140px)*stripUnit($font-size-base);
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  ul.menu {
    flex-grow: 1;
    list-style: none;
    margin-top: $halfGutter;
    margin-left: $min-inline-element-spacing;
    padding-left: 0px;
    li+li {
      margin-top: $min-inline-element-spacing;
    }

    .fa {
      width: 15px;
    }
    .item-txt {
      margin-left: $min-inline-element-spacing;
    }
    a {
      color: $gray-800;
      @include a-events-hover() {
        color: $primary;
      }
    }
  }
  .exit {
    align-self: flex-start;
    margin-left: $min-inline-element-spacing;
    font-size: $font-size-sm;
    .txt {
      margin-left: $min-inline-element-spacing;
    }
  }

  @media screen and (max-width: 768px) {
    @include mobile();
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    @include minimized();
    .sidebar .button {
      display: none;
    }
  }
}
ul.nav.nav-tabs {
  border-bottom: 0px;
  .nav-item {
    button {
      border: 0px;
      background-color: inherit;
      font-weight: 600;
      margin: 0px;
      padding: 0px;

      &.active {
        border-bottom: 3px solid $primary;
      }
    }
    & + .nav-item {
      margin-left: $min-inline-element-spacing;
    }
  }
}


.tab-content {
  margin: $halfGutter;
}

@media print {
  .sidebar {
    display: none !important;
  }
  .content {
    margin: 0px !important;
  }
}