$primary: #980046;
$font-family-base: -apple-system, BlinkMacSystemFont,
                  "Segoe UI", "Roboto", "Oxygen",
                  "Ubuntu", "Cantarell", "Fira Sans",
                  "Droid Sans", "Helvetica Neue", sans-serif;
$border-radius: 0px;
$font-size-base: 0.8rem;
$spacer: $font-size-base;
$label-margin-bottom: 0.5*$font-size-base;
$form-group-margin-bottom: 0.3*$font-size-base;
$grid-gutter-width: $font-size-base;
$card-spacer-x: $grid-gutter-width/2;
$font-size-lg:  $font-size-base * 1.05;
$font-size-sm:  $font-size-base *  .95;

$grid-columns: 24;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1880px
);



//$modal-content-border-radius: 0px;
//$modal-content-box-shadow-xs: 3px 3px 3px #0c0c0c;


