.btn-rounded {
  padding: 0px !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
a.btn-rounded {
  display: inline-flex !important;
  justify-content: center;
  align-content: center;
  align-items: center;
}

ul.operator-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
  > li {
    @include clearfix;
    padding: $min-inline-element-spacing 0px;

    button {
      padding: 0px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      float: right;
    }
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $gray-300;
      font-weight: 600;
      font-size: $font-size-xsm;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    & + li {
      border-top: 1px solid $gray-200;
    }
  }
}
